body {
  margin: 0;
  background-color: #f8f8f8;
  font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-menubar {
  padding: 0 0.5rem !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1.75rem 1.25rem !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.products-quantity > .p-inputnumber-input {
  width: 3rem;
}

.p-autocomplete > input {
  width: 100%;
}

@media screen and (max-width: 748px) {
  .p-dialog-header-maximize,
  .d-mobile-none {
    display: none !important;
  }
}
